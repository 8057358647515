import { Model } from "@vuex-orm/core";
import Insurer from "./Insurer";
import User from "./User";
import DossierQuestionnaire from "./DossierQuestionnaire";

export default class Dossier extends Model {
  static get entity() {
    return "dossiers";
  }

  static fields() {
    return {
      id: this.attr(null),
      accessHash: this.attr(null),
      product: this.attr(null),
      status: this.attr(null),
      actionStatus: this.attr(null),
      cancelReason: this.attr(null),
      rejectReason: this.attr(null),
      deleteReason: this.attr(null),
      activePhase: this.attr(null),
      insurerId: this.attr(null),
      insurer: this.belongsTo(Insurer, "insurerId"),
      insurerCommunicationResponsibleId: this.attr(null),
      partnerId: this.attr(null),
      partner: this.belongsTo(User, "partnerId"),
      responsibleId: this.attr(null),
      responsible: this.belongsTo(User, "responsibleId"),
      dateStartSite: this.attr(null),
      dateEndSite: this.attr(null),
      dateStartWork: this.attr(null),
      buildingType: this.attr(null),
      customBuildingType: this.attr(null),
      constructionType: this.attr(null),
      usage: this.attr(null),
      customUsage: this.attr(null),
      totalDeclared: this.attr(null),
      totalDeclaredType: this.attr(null),
      totalDevis: this.attr(null),
      totalInvoiced: this.attr(null),
      address: this.attr(null),
      zipcode: this.attr(null),
      city: this.attr(null),
      latitude: this.attr(null),
      longitude: this.attr(null),
      buildingAddress: this.attr(null),
      buildingZipcode: this.attr(null),
      buildingCity: this.attr(null),
      buildingCountry: this.attr(null),
      buildingLatitude: this.attr(null),
      buildingLongitude: this.attr(null),
      buildingsNumber: this.attr(0),
      roomsNumber: this.attr(0),
      floorsNumber: this.attr(0),
      builtArea: this.attr(null),
      habitableArea: this.attr(null),
      pool: this.attr(0),
      closedAndCovered: this.attr(null),
      groundSlope: this.attr(null),
      buildingSituation: this.attr(null),
      buildingAltitudeSituation: this.attr(null),
      dossierOfferId: this.attr(null),
      technicalContractors: this.attr(null),
      demandedWarranties: this.attr(null),
      phasesStatuses: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      clientId: this.attr(null),
      client: this.belongsTo(User, "clientId"),
      contactTitle: this.attr(null),
      contactEmail: this.attr(null),
      contactFirstname: this.attr(null),
      contactLastname: this.attr(null),
      contactPhone: this.attr(null),
      secondContactEmail: this.attr(null),
      secondContactFirstname: this.attr(null),
      secondContactLastname: this.attr(null),
      secondContactPhone: this.attr(null),
      secondContactTitle: this.attr(null),
      hasSecondContact: this.attr(null),
      contactAddress: this.attr(null),
      contactZipcode: this.attr(null),
      contactCity: this.attr(null),
      contactCountry: this.attr(null),
      contactLatitude: this.attr(null),
      contactLongitude: this.attr(null),
      contactCompanyName: this.attr(null),
      contactSiret: this.attr(null),
      contactCodeApe: this.attr(null),
      contactTvaIntracom: this.attr(null),
      contactUserType: this.attr(null),
      architectMissions: this.attr(null),
      managerMissions: this.attr(null),
      basementsNumber: this.attr(0),
      businessPremisesNumber: this.attr(0),
      contactCompanyForm: this.attr(null),
      representativeFirstname: this.attr(null),
      representativeLastname: this.attr(null),
      representativeFunction: this.attr(null),
      amountExisting: this.attr(null),
      amountExistingType: this.attr(null),
      agencyCompany: this.attr(null),
      dossierQuestionnaire: this.hasOne(DossierQuestionnaire, "dossierId"),
      repeatAddress: this.attr(false),
      leadExternalId: this.attr(false),
      comment: this.attr(false),
      mailPartner: this.attr(false),
      callPeriod: this.attr(false),
      leadOrigin: this.attr(false),
      lastInvalidDocsSynthesisReminder: this.attr(null),
      countInvalidDocsSynthesisReminders: this.attr(null),
      horizontalSpan: this.attr(null),
      historicalMonuments: this.attr(null),
      renovationRehabilitationWorks: this.attr(null),
      contactBorn: this.attr(null),
      contactPlaceBirth: this.attr(null),
      contactCreationDate: this.attr(null),
      advancePaymentAmountPaid: this.attr(null),
      communicationDestination: this.attr(null),
      uploadFirstDocDate: this.attr(null),
      phase5ValidatedAt: this.attr(null),
      phase6ValidatedAt: this.attr(null),
      datePaymentFee: this.attr(null),
      note: this.attr(null),
      creationDateOfFirstAcceptedOffer: this.attr(null),
      contractGenerateDate: this.attr(null),
      datePaymentAdvanceFee: this.attr(null),
      suspendCommunication: this.attr(null),
      dossierOffer: this.attr(null),

      appelPrimeStatus: this.attr(null),
      hasInsurerReturnInValidation: this.attr(null),
      documentNumber: this.attr(null),

      hasAdditionalCorrespondent: this.attr(null),
      additionalCorrespondentFirstname: this.attr(null),
      additionalCorrespondentLastname: this.attr(null),
      additionalCorrespondentPhone: this.attr(null),
      additionalCorrespondentEmail: this.attr(null),
      dossierOfferPrice: this.attr(null),
      operationIncludeActivities: this.attr(null),
      insurerCommunicationEmailPhase5: this.attr(null),
      insurerCommunicationEmailPhase7: this.attr(null),
      dateReceipt: this.attr(null),
      secondResponsibleId: this.attr(null),
      secondResponsible: this.belongsTo(User, "secondResponsibleId"),
      invalidDocumentsComment: this.attr(null),
      depositRetained: this.attr(null),
      depositRefunded: this.attr(null),
      dateRefundDeposit: this.attr(null),

      contactCheckedStatus: this.attr(null),
      contactCheckedHistory: this.attr(null),
      questionnaireFirstSubmissionDate: this.attr(null),
      locked: this.attr(null),

      dossierQuestionnaireRowData: this.attr(null),
      hasFinalValidationInsurerInValidation: this.attr(null),
      validationDate: this.attr(null),
      dateOfPostponement: this.attr(null),
      reasonOfPostponement: this.attr(null),
      dateEffectiveAmendment: this.attr(null),
      proofPaymentIsPaid: this.attr(null),
      totalSpentTime: this.attr(null),
      structureCreationStatus: this.attr(null),
    };
  }
}
